import { concat, concatMap, delay, filter, mergeMap, Observable, of, Subscription } from 'rxjs';
import { createEnvironmentInjector, EnvironmentInjector, Injectable, OnDestroy } from '@angular/core';
import {
  LoadedRouterConfig,
  NavigationEnd,
  PreloadingStrategy,
  Route,
  Router,
  RouterConfigLoader,
  Routes,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterPreloader implements OnDestroy {
  private subscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly injector: EnvironmentInjector,
    private readonly preloadingStrategy: PreloadingStrategy,
    private readonly loader: RouterConfigLoader,
  ) {}

  setUpPreloading(): void {
    this.subscription = this.router.events
      .pipe(
        filter((e: unknown) => e instanceof NavigationEnd),
        concatMap(() => this.preload()),
      )
      .subscribe();
  }

  preload(): Observable<unknown> {
    return this.processRoutes(this.injector, this.router.config);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private processRoutes(injector: EnvironmentInjector, routes: Routes): Observable<unknown> {
    const res: Observable<unknown>[] = [];

    for (const route of routes) {
      if (route.providers && !route._injector) {
        route._injector = createEnvironmentInjector(route.providers, injector, `Route: ${route.path}`);
      }

      const injectorForCurrentRoute = route._injector ?? injector;
      const injectorForChildren = route._loadedInjector ?? injectorForCurrentRoute;

      if (
        (route.loadChildren && !route._loadedRoutes && route.canLoad === undefined) ||
        (route.loadComponent && !route._loadedComponent)
      ) {
        res.push(this.preloadConfig(injectorForCurrentRoute, route));
      }

      if (route.children || route._loadedRoutes) {
        res.push(this.processRoutes(injectorForChildren, route.children ?? route._loadedRoutes));
      }
    }

    return concat(res).pipe(delay(1000));
  }

  private preloadConfig(injector: EnvironmentInjector, route: Route): Observable<unknown> {
    return this.preloadingStrategy.preload(route, () => {
      let loadedChildren$: Observable<LoadedRouterConfig | null>;

      if (route.loadChildren) {
        loadedChildren$ = this.loader.loadChildren(injector, route).pipe(delay(1000));
      } else {
        loadedChildren$ = of(null);
      }

      const recursiveLoadChildren$ = loadedChildren$.pipe(
        mergeMap((config: LoadedRouterConfig | null) => {
          if (config === null) {
            return of(void 0);
          }
          route._loadedRoutes = config.routes;
          route._loadedInjector = config.injector;

          return this.processRoutes(config.injector ?? injector, config.routes);
        }),
      );

      if (route.loadComponent && !route._loadedComponent) {
        const loadComponent$ = this.loader.loadComponent(route);

        return concat([recursiveLoadChildren$, loadComponent$]).pipe(delay(1000));
      } else {
        return recursiveLoadChildren$.pipe(delay(1000));
      }
    });
  }
}
