import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, PreloadingStrategy, ROUTER_PRELOADER, RouterModule } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';

import { AuthenticationStateModule, HTTP_AUTHENTICATION_INTERCEPTOR_PROVIDERS } from '@supy/authentication';
import { PermissionHelper, PermissionsState } from '@supy/authz';
import {
  CustomRouterStateSerializer,
  HTTP_COMMON_INTERCEPTOR_PROVIDERS,
  reduxDevToolsActionSanitizer,
  reduxDevToolsStateSanitizer,
  UnleashModule,
} from '@supy/common';
import {
  HTTP_NOT_FOUND_INTERCEPTOR_PROVIDERS,
  IntercomModule,
  NotFoundModule,
  SNACKBAR_INTERCEPTOR,
  SplashScreenModule,
  VersionCheckModule,
} from '@supy/components';
import { APP_CONFIG, APP_VERSION } from '@supy/core';
import { CountriesModule } from '@supy/countries';
import { NotificationsState } from '@supy/notifications';
import { OpenTelemetryModule } from '@supy/opentelemetry';
import { RetailerItemCategoriesState } from '@supy/retailer-item-categories';
import { CurrentRetailerState, HTTP_RETAILERS_INTERCEPTOR_PROVIDERS } from '@supy/retailers';
import { SettingsState } from '@supy/settings';
import { ImpersonationRetailersState } from '@supy/user-impersonation';
import { CurrentUserState, UsersModule } from '@supy/users';
import { WebSocketModule } from '@supy/websockets';

import { environment } from '../environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components';
import { I18nModule } from './modules/i18n/i18n.module';
import { RetailerPermissionHelper } from './modules/shared';
import { RouterPreloader } from './router-preloader';

const STARTER_MODULES = [UsersModule, CountriesModule];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HammerModule,
    SplashScreenModule,
    NotFoundModule,
    VersionCheckModule,
    IntercomModule.forRoot(),
    NgxsModule.forRoot(
      [
        CurrentRetailerState,
        ImpersonationRetailersState,
        CurrentUserState,
        SettingsState,
        PermissionsState,
        RetailerItemCategoriesState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
      },
    ),
    NgxsStoragePluginModule.forRoot({
      keys: [
        ImpersonationRetailersState,
        'currentUser.user',
        'currentUser.favouriteReports',
        CurrentRetailerState,
        'tenant.reconnectingTenantId',
        NotificationsState,
      ],
      storage: StorageOption.LocalStorage,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      actionSanitizer: reduxDevToolsActionSanitizer,
      stateSanitizer: reduxDevToolsStateSanitizer,
    }),
    NgxsActionsExecutingModule.forRoot(),
    OpenTelemetryModule.forRoot(environment.opentelemetry),
    AuthenticationStateModule,
    UnleashModule.forRoot(
      {
        appName: environment.unleash.appName,
        clientKey: environment.unleash.clientKey,
        url: environment.unleash.proxyUrl,
      },
      false,
    ),
    WebSocketModule.forRoot({
      clients: environment.websockets.clients,
    }),
    ...STARTER_MODULES,
  ],
  providers: [
    I18nModule.setLocale(),
    I18nModule.setLocaleId(),
    ...HTTP_COMMON_INTERCEPTOR_PROVIDERS,
    ...HTTP_NOT_FOUND_INTERCEPTOR_PROVIDERS,
    ...HTTP_AUTHENTICATION_INTERCEPTOR_PROVIDERS,
    ...HTTP_RETAILERS_INTERCEPTOR_PROVIDERS,
    SNACKBAR_INTERCEPTOR,
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_VERSION,
      useValue: 'beta',
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: ROUTER_PRELOADER, useExisting: RouterPreloader },
    { provide: PreloadingStrategy, useClass: PreloadAllModules },
    {
      provide: PermissionHelper,
      useClass: RetailerPermissionHelper,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
